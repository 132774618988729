import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.css";
import Logo from "../../Assets/Svg/Logo.svg";

export default function EnglishHeader() {
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLanguageChange = () => {
    const currentPath = location.pathname;
    const isArabic = currentPath.endsWith("/ar");
    const basePath = isArabic ? currentPath.slice(0, -3) : currentPath;
    const newPath = isArabic ? basePath : `${basePath}${basePath.endsWith("/") ? "ar" : "/ar"}`;
    navigate(newPath, { replace: true });
  };

  const handleScroll = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="EnHeaderContainer">
        <div className="EnHeaderL">
          <Link to="/">
            <img src={Logo} alt="Logo" />
          </Link>
        </div>
        <div className="EnHeaderR">
          <a href="#HomeS4" onClick={(e) => handleScroll(e, "HomeS3")}>
            Features
          </a>
          <a href="#SomeOtherSection" onClick={(e) => handleScroll(e, "HomeS5")} className="LinkSpan">
            About <span> Us</span>
          </a>
        </div>
      </div>
    </>
  );
}
