import React, { useState, useEffect } from "react";
import "./Home.css";
import LogoS1P1 from "../../Assets/Svg/HomeS1P1.svg";
import GooglePlay from "../../Assets/Svg/GooglePlay.svg";
import AppStore from "../../Assets/Svg/AppStore.svg";
import HomeS1P2 from "../../Assets/Svg/HomeS1P2.svg";
import HomeS2N1 from "../../Assets/Svg/HomeS2N1.svg";
import HomeS2N2 from "../../Assets/Svg/HomeS2N2.svg";
import HomeS2S1 from "../../Assets/Images/HomeS2S1.png";
import HomeS2S2 from "../../Assets/Images/HomeS2S2.png";
import HomeS2S3 from "../../Assets/Images/HomeS2S3.png";
import HomeS2S4 from "../../Assets/Images/HomeS2S4.png";
import HomeS2S5 from "../../Assets/Images/HomeS2S5.png";
import HomeS2S6 from "../../Assets/Images/HomeS2S6.png";
import HomeS2S7 from "../../Assets/Images/HomeS2S7.png";
import HomeS2S8 from "../../Assets/Images/HomeS2S8.png";
import HomeS3P2BImg1 from "../../Assets/Svg/HomeS3P2BImg1.svg";
import HomeS3P2BImg2 from "../../Assets/Svg/HomeS3P2BImg3.svg";
import HomeS3P2BImg3 from "../../Assets/Svg/HomeS3P2BImg2.svg";
import ScanYellow from "../../Assets/Svg/Scan-Yellow.svg";
import HomeS5S1 from "../../Assets/Svg/HomeS5S1.svg";
import HomeS5S2 from "../../Assets/Svg/HomeS5S2.svg";
import HomeS5S3 from "../../Assets/Svg/HomeS5S3.svg";
import HomeS5S4 from "../../Assets/Svg/HomeS5S4.svg";
import HomeS5S5 from "../../Assets/Svg/HomeS5S5.svg";
export default function HomeContent() {
  return (
    <>
      <div className="Home_Container">
        <HomeS1 />
        <HomeS2 />
        <HomeS3 />
        <HomeS4 />
        <HomeS5 />
      </div>
    </>
  );
}
function HomeS1() {
  return (
    <>
      <div className="HomeS1">
        {/*  */}
        <div className="HomeS1First">
          <div className="HomeS1P1">
            <div className="HomeS1P1V1">
              <img src={LogoS1P1} alt="" className="HomeS1P1V1Img" />
            </div>
            <div className="HomeS1P1V2">
              Never Miss a Deadline Again. <br />
              Scan, track, and stay organized.
            </div>
            <div className="HomeS1P1V3">
              <a href="/">
                <img src={GooglePlay} alt="" className="HomeS1P1V3Img" />
              </a>
              <a href="/">
                <img src={AppStore} alt="" className="HomeS1P1V3Img" />
              </a>
            </div>
          </div>
          {/*  */}
          <div className="HomeS1P2">
            <div className="HomeS1P2Content">
              <img src={HomeS1P2} alt="" className="HomeS1P2Img" />
              <div className="HomeS1P2Text">Scan & Remind</div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="HomeS1P3">
          <div className="scrolling-content">
            Scan All your documents <span className="dot"></span>App Reminder <span className="dot"></span>
            Document Organization <span className="dot"></span> Documents Scan & Ocr <span className="dot"></span>Reminder System
          </div>
        </div>
      </div>
    </>
  );
}

function HomeS2() {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [HomeS2S1, HomeS2S2, HomeS2S3, HomeS2S4, HomeS2S5, HomeS2S6, HomeS2S7, HomeS2S8];
  const altTexts = ["aaa"];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <>
      <div className="HomeS2">
        <div className="HomeS2P1">
          {/*  */}
          <div className="HomeS2P1T">
            <div className="HomeS2P1T1">Scanning And Reminding </div>
            <div className="HomeS2P1T2">
              Just two simple steps! <br />
              Scan your document and let xam send you a reminder.
            </div>
          </div>
          {/*  */}
          <div className="HomeS2P1B">
            <div className="HomeS2P1B1">
              <img src={HomeS2N1} alt="" className="HomeS2NImg" />
              <div className="HomeS2P1B1T">
                <div className="HomeS2P1B1TH">Scanning Documents</div>
                <div className="HomeS2P1B1TP">Our app supports a wide range of document types, from receipts and invoices to business cards and ID </div>
              </div>
            </div>
            <div className="HomeS2P1B1">
              <img src={HomeS2N2} alt="" className="HomeS2N2Img" />
              <div className="HomeS2P1B1T">
                <div className="HomeS2P1B1TH">Reminder & due dates tracking</div>
                <div className="HomeS2P1B1TP">
                  Our app offers flexible reminder options, including time-based and location-based alerts, to ensure you never miss a deadline or important
                  tasks
                </div>
              </div>
            </div>
          </div>
          {/*  */}
        </div>
        {/*  */}
        <div className="HomeS2P2">
          <img src={images[currentImage]} alt={altTexts[currentImage]} className="HomeS2P2Img" />
        </div>
      </div>
      {/*  */}
      {/* For Mob */}
      {/*  */}
      <div className="HomeS2Mob">
        <div className="HomeS2P1">
          {/*  */}
          <div className="HomeS2P1T">
            <div className="HomeS2P1T1">Scanning And Reminding </div>
            <div className="HomeS2P1T2">
              Just two simple steps! <br />
              Scan your document and let xam send you a reminder.
            </div>
          </div>
          <div className="HomeS2P2">
            <img src={images[currentImage]} alt={altTexts[currentImage]} className="HomeS2P2Img" />
          </div>
          {/*  */}
          <div className="HomeS2P1B">
            <div className="HomeS2P1B1">
              <img src={HomeS2N1} alt="" className="HomeS2NImg" />
              <div className="HomeS2P1B1T">
                <div className="HomeS2P1B1TH">Scanning Documents </div>
                <div className="HomeS2P1B1TP">Our app supports a wide range of document types, from receipts and invoices to business cards and ID </div>
              </div>
            </div>
            <div className="HomeS2P1B1">
              <img src={HomeS2N2} alt="" className="HomeS2N2Img" />
              <div className="HomeS2P1B1T">
                <div className="HomeS2P1B1TH">
                  Reminder & Due dates <br /> Tracking
                </div>
                <div className="HomeS2P1B1TP">
                  Our app offers flexible reminder options, including time-based and location-based alerts, to ensure you never miss a deadline or important
                  tasks
                </div>
              </div>
            </div>
          </div>
          {/*  */}
        </div>
        {/*  */}
      </div>
    </>
  );
}

function HomeS3() {
  return (
    <>
      <div id="HomeS3" className="HomeS3">
        <div className="HomeS3P1">
          <div className="HomeS3P1B1">Other Features</div>
          <div className="HomeS3P1B2">
            <div className="HomeS3P1B2R">Efficiency and Accuracy</div>
            <div className="HomeS3P1B2B">
              xam advanced OCR technology ensures precise, detailed, and efficient document scanning, <br /> expiration dates reminders and data documentation.
            </div>
          </div>
        </div>
        {/*  */}
        <div className="HomeS3P2">
          {/*  */}
          <div className="HomeS3P2B">
            <img src={HomeS3P2BImg1} alt="" className="HomeS3P2BImg2" />
            <div className="HomeS3P2BH">Document Organization</div>
            <div className="HomeS3P2BP">Keep your documents organized in separate folders.</div>
          </div>
          {/*  */}
          <div className="HomeS3P2B">
            <img src={HomeS3P2BImg2} alt="" className="HomeS3P2BImg2 Up" />
            <div className="HomeS3P2BH">Documents Ocr</div>
            <div className="HomeS3P2BP">OCR technology precisely extracts text data like name, expiry date, etc. from scanned documents.</div>
          </div>
          {/*  */}
          <div className="HomeS3P2B">
            <img src={HomeS3P2BImg3} alt="" className="HomeS3P2BImg2" />
            <div className="HomeS3P2BH">Expiration Tracking</div>
            <div className="HomeS3P2BP">Helps you keep an eye on every expiration date and documents renewal.</div>
          </div>
          {/*  */}
        </div>
      </div>
    </>
  );
}

function HomeS4() {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [HomeS2S1, HomeS2S2, HomeS2S3, HomeS2S4, HomeS2S5, HomeS2S6, HomeS2S7, HomeS2S8];
  const altTexts = ["aaa"];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <>
      <div className="HomeS4">
        <div className="HomeS4P2">
          <img src={images[currentImage]} alt={altTexts[currentImage]} className="HomeS4P2Img" />
          <img src={ScanYellow} alt="" className="ScanYellow" />
        </div>
        {/*  */}
        <div className="HomeS4P1">
          {/*  */}
          <div className="HomeS2P1T">
            <div className="HomeS4P1TM">Simplify Your Life, One Scan at a Time</div>
            <div className="HomeS4P1T1">Time to Snap with a single Tap!</div>
          </div>
          {/*  */}
          <div className="HomeS4P1B">
            <div>We know how essential it is to keep track of important expiration dates, whether it’s for you or your family. </div>
            <div>That’s why we’ve made it our mission to ensure you never miss a critical date, assignment, or official document renewal. </div>{" "}
            <div>With xam, staying on top of your reminders is easier and simpler than ever before. </div>
            <div>Just scan your documents, enjoy a peace of mind and let xam streamline your life.</div>
            <a href="/">Download xam today and experience the future of effortless reminders..</a>
          </div>
          {/*  */}
        </div>
        {/*  */}
      </div>
      {/*  */}
      {/* For Mob */}
      {/*  */}
      <div className="HomeS4Mob">
        {/*  */}
        <div className="HomeS4P1">
          {/*  */}
          <div className="HomeS2P1T">
            <div className="HomeS4P1TM">Simplify Your Life, One Scan at a Time</div>
            <div className="HomeS4P1T1">Time to Snap with a single Tap!</div>
          </div>
          <div className="HomeS4P2">
            <img src={images[currentImage]} alt={altTexts[currentImage]} className="HomeS4P2Img" />
            <img src={ScanYellow} alt="" className="ScanYellow" />
          </div>
          {/*  */}
          <div className="HomeS4P1B">
            <div>We know how essential it is to keep track of important expiration dates, whether it’s for you or your family. </div>
            <div>That’s why we’ve made it our mission to ensure you never miss a critical date, assignment, or official document renewal. </div>{" "}
            <div>With xam, staying on top of your reminders is easier and simpler than ever before. </div>
            <div>Just scan your documents, enjoy a peace of mind and let xam streamline your life.</div>
            <a href="/">Download xam today and experience the future of effortless reminders..</a>
          </div>
          {/*  */}
        </div>
        {/*  */}
      </div>
    </>
  );
}
function HomeS5() {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [HomeS5S1, HomeS5S2, HomeS5S3, HomeS5S4, HomeS5S5];
  const altTexts = ["aaa"];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [images.length]);
  return (
    <>
      <div id="HomeS5" className="HomeS5">
        <div className="HomeS5P2">
          <img src={images[currentImage]} alt={altTexts[currentImage]} className="HomeS5P2Img" />
        </div>
        {/*  */}
        <div className="HomeS5P1">
          {/*  */}
          <div className="HomeS5P1T">
            <div className="HomeS5P1TM">About Us</div>
          </div>
          {/*  */}
          <div className="HomeS5P1B">
            <div>
              At Xam, we believe that technology and AI should empower people, not complicate their lives. That’s why we developed xam, your intelligent
              reminder companion.
            </div>
            <div>
              {" "}
              Leveraging advanced OCR technology, xam keeps track of your important documents expiration dates from bills to passports and national IDs.{" "}
            </div>{" "}
            <div>
              {" "}
              It remembers everything! so you can rest easy, knowing everything is organized and up-to-date. xam sends you gentle reminders up to a month in
              advance for any upcoming expiration dates, ensuring you're always ahead on renewing your essential documents. Let xam take care of the details.
            </div>
          </div>
          {/*  */}
        </div>
        {/*  */}
      </div>
      {/*  */}
      {/* For Mob */}
      {/*  */}
      <div id="HomeS5Mob" className="HomeS5Mob">
        {/*  */}
        <div className="HomeS5P1">
          {/*  */}
          <div className="HomeS5P1T">
            <div className="HomeS5P1TM">About Us</div>
          </div>
          {/*  */}
          <div className="HomeS5P2">
            <img src={images[currentImage]} alt={altTexts[currentImage]} className="HomeS5P2Img" />
          </div>
          {/*  */}
          <div className="HomeS5P1B">
            <div>
              At Xam, we believe that technology and AI should empower people, not complicate their lives. That’s why we developed xam, your intelligent
              reminder companion.
            </div>
            <div>
              {" "}
              Leveraging advanced OCR technology, xam keeps track of your important documents expiration dates from bills to passports and national IDs.{" "}
            </div>{" "}
            <div>
              {" "}
              It remembers everything! so you can rest easy, knowing everything is organized and up-to-date. xam sends you gentle reminders up to a month in
              advance for any upcoming expiration dates, ensuring you're always ahead on renewing your essential documents. Let xam take care of the details.
            </div>
          </div>
          {/*  */}
        </div>
        {/*  */}
      </div>
    </>
  );
}
