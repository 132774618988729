import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate from React Router
import "./Header.css";

export default function ArabicHeader() {
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); // Changed to useNavigate

  const handleLanguageSwitch = () => {
    const currentPath = location.pathname;
    const isArabic = currentPath.includes("/ar"); // Check if current language is Arabic

    if (isArabic) {
      const basePath = currentPath.slice(0, -3); // Remove "/ar" from the current path

      // If the basePath is empty, it means we are at the root in Arabic version
      const newPath = basePath === "" ? "/" : basePath;

      navigate(newPath, { replace: true }); // Navigate without pushing a new history entry
    }
  };

  return <></>;
}
