import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./HeaderMob.css";
import Logo from "../../Assets/Svg/Logo.svg";
import { Link } from "react-router-dom";
export default function EnglishHeaderMobile() {
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLanguageChange = () => {
    const currentPath = location.pathname;
    const isArabic = currentPath.endsWith("/ar");
    const basePath = isArabic ? currentPath.slice(0, -3) : currentPath;
    const newPath = isArabic ? basePath : `${basePath}${basePath.endsWith("/") ? "ar" : "/ar"}`;
    navigate(newPath, { replace: true });
    setShowDropdown(false); // Close dropdown after language change
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setShowDropdown(false);
  };

  const handleScroll = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="EnHeaderContainerMob">
        {/*  */}
        <div className="EnHeaderLMob">
          <Link to="/">
            <img src={Logo} alt="" />
          </Link>
        </div>
        {/*  */}
        <div className="EnHeaderRMob">
          <a href="#HomeS4" onClick={(e) => handleScroll(e, "HomeS3")}>
            Features
          </a>
          <a href="#SomeOtherSection" onClick={(e) => handleScroll(e, "HomeS5Mob")} className="LinkSpanMob">
            About <span> Us</span>
          </a>
        </div>
        {/*  */}
      </div>
    </>
  );
}
