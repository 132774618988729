import React from "react";
import "./Footer.css";
import Logo from "../../Assets/Svg/Logo.svg";
import Facebook from "../../Assets/Svg/Facebook.svg";
import Insta from "../../Assets/Svg/Insta.svg";
import XTwitter from "../../Assets/Svg/X.svg";
import GooglePlay from "../../Assets/Svg/GooglePlay.svg";
import AppStore from "../../Assets/Svg/AppStore.svg";
import { Link } from "react-router-dom";
export default function EnglishFooterMobile() {
  return (
    <>
      <div className="EnglishFooterMain">
        <div className="FooterS1">
          {/*  */}
          <div className="FooterS1P1">
            <Link to="/">
              <img src={Logo} alt="" />
            </Link>
          </div>
          {/*  */}
          <div className="FooterS1P2">
            <div className="FooterS1P2V1">Our Social Media</div>
            <div className="FooterS1P2V2">
              <a href="/" target="blank">
                <img src={Facebook} alt="" className="FooterS1P2Img" />
              </a>
              <a href="/" target="blank">
                <img src={Insta} alt="" className="FooterS1P2Img" />
              </a>{" "}
              <a href="/" target="blank">
                <img src={XTwitter} alt="" className="FooterS1P2Img" />
              </a>
            </div>
          </div>
          {/*  */}
          <div className="FooterS1P3">
            <div className="FooterS1P2V1">Download Now</div>
            <div className="FooterS1P2V2Mob">
              <a href="/" target="blank">
                <img src={GooglePlay} alt="" className="FooterS1P3Img" />
              </a>
              <a href="/" target="blank">
                <img src={AppStore} alt="" className="FooterS1P3Img" />
              </a>{" "}
            </div>
          </div>
        </div>
        <div className="FooterS2">
          <div>
            Copyright © 2024 xam - <span> All Rights Reserved.</span>
          </div>
        </div>
      </div>
    </>
  );
}
