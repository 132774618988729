import React from "react";
import "./Footer.css";

export default function ArabicFooterMobile() {
  return (
    <>
      <div></div>
    </>
  );
}
